import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Popover, PopoverBody } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../CustomDatePicker2';
import CustomDatePicker4 from '../CustomDatePicker4';
import config from '../../../config';
import { formatPriceWithoutCurrency, isNYDST } from '../../../helpers';
import request from 'superagent';

class SeasonPopup extends React.Component {

    state = {
        step: 1,
        season: null,
        isOpenStartDatepicker: false,
        isOpenEndDatepicker: false,
        microTransac: false,
        error: null,
        dirty: false,
        incompleteForm: false,
        validations: {
            name: this.props.season.Name ? true : false,
            type: this.props.season.IdSeasonType ? true : false,
        },
        timezone: 0,
        timezones: config.timezones
    }

    // Lifecycle
    componentWillMount() {
        const { season } = this.props;

        if (season.ShowEntryListOrGroup === null || season.ShowEntryListOrGroup === undefined) {
            season.ShowEntryListOrGroup = 1;
        }

        // NEW: the date in the DB is in UTC. however we're displaying here with the offset, so 
        // we add the offset to the registration date, then when this component closes, we 
        // substract the offset again
        if (season.TimeOffset === null) season.TimeOffset = 0;
        season.RegistrationEnd = moment(season.RegistrationEnd || season.DateEnd).add(season.TimeOffset, 'minutes');
        this.setState({ season, timezone: season.USE_DLS || season.TimeOffset });
        //

        // Fetch additional things
        this.props.leagueActions && this.props.leagueActions.fetchMembershipPrograms(season.IdLeague);
        this.props.leagueActions && this.props.leagueActions.fetchTournamentSeasons(season.IdLeague);
        this.props.seasonActions && this.props.seasonActions.fetchMedals(season.IdSeason || season.Id)
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ medals: nextProps.medals });
    }
    componentDidMount() {
        this.txtSeasonName.focus();
        this.txtSeasonName.select();
    }
    componentWillUnmount = () => {
        const { season } = this.props;
        season.RegistrationEnd = moment(season.RegistrationEnd || season.DateEnd).add(season.TimeOffset * -1, 'minutes');
    }
    //

    saveSeason = () => {
        const { season, timezone } = this.state,
            { league, seasonActions, toggle } = this.props;

        // NEW: timezone will be the offset of the UTC time and hour that we're gonna handle        
        season.TimeOffset = timezone;

        this.setState({ dirty: true }, () => {
            if (this.isValid()) {
                this.setState({
                    microTransac: true,
                    updated: false,
                    error: null
                }, () => {

                    if (isNYDST()) {
                        season.USE_DLS = true;
                    }

                    // Smart Call API
                    request[season.IdSeason ? 'patch' : 'post'](`${config.apiEndpoint}/api/v4/seasons/${season.IdSeason || league.IdLeague}`)
                        .send({
                            ...season,
                            RegistrationEnd:
                                moment(moment(season.RegistrationEnd)).add(timezone * -1, 'minutes')
                                    .add(season.USE_DLS ? -60 : 0, 'minutes')
                        })
                        .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                        .then((data) => {

                            // Update the storeb  
                            if (season.IdSeason) {
                                seasonActions && seasonActions.update(season.IdSeason, _.extend(season, {
                                    IdSeason: season.IdSeason,
                                    SeasonType: _.find(config.seasonTypes, function (st) {
                                        return st.Id === season.IdSeasonType
                                    }).Name,
                                    Updated: true // this adds like a ribon at the top
                                }));
                                this.setState({
                                    microTransac: false,
                                    updated: true
                                });
                            } else {
                                // Consider go into the dashboard of the season ?
                                seasonActions && seasonActions.add(_.extend(season, {
                                    IdSeason: data.body.IdSeason,
                                    SeasonType: _.find(config.seasonTypes, st => st.Id === season.IdSeasonType).Name,
                                    BrandNew: true // this adds like a ribon at the top
                                }));
                                // Scroll to it ? if possible ? (or add it with kind of a glow)
                                toggle && toggle();
                            }
                        }, ({ original, response }) => {
                            this.setState({
                                microTransac: false,
                                error: (response && response.body) ? response.body.error : null
                            });
                        });
                });
            }
        });
    }

    handleChangeStart = date => {
        const { season } = this.state;
        season.DateStart = moment(date);
        season.DateEnd = moment(date).isAfter(moment(season.DateEnd)) ? moment(date).add(1, 'day') : moment(season.DateEnd);
        this.setState({ season }, () => this.toggleCalendarStart());
    }
    handleChangeEnd = date => {
        const { season } = this.state;
        season.DateStart = moment(date).isBefore(moment(season.DateStart)) ? moment(date).add(-1, 'day') : moment(season.DateStart);
        season.DateEnd = moment(date);
        this.setState({ season }, () => this.toggleCalendarEnd());
    }

    handleChangeRegistrationDate = date => {
        const { season } = this.state;
        season.RegistrationEnd = moment(date);
        this.setState({ season });
    }

    handleChangeScheduleStart = date => {
        const { season } = this.state;
        season.ScheduleStartDate = moment(date);
        if (season.ScheduleEndDate && moment(date).isAfter(moment(season.ScheduleEndDate))) season.ScheduleEndDate = moment(date).add(1, 'day');
        this.setState({ season }, () => this.toggleCalendarScheduleStart());
    }

    handleChangeScheduleEnd = date => {
        const { season } = this.state;
        if (season.ScheduleStartDate && moment(date).isBefore(moment(season.ScheduleStartDate))) season.ScheduleStartDate = moment(date).add(-1, 'day');
        season.ScheduleEndDate = moment(date);
        this.setState({ season }, () => this.toggleCalendarScheduleEnd());
    }

    toggleCalendarScheduleStart = e => {
        e && e.preventDefault()
        this.setState({
            isOpenScheduleStartDatepicker: !this.state.isOpenScheduleStartDatepicker
        })
    }
    toggleCalendarScheduleEnd = e => {
        e && e.preventDefault()
        this.setState({
            isOpenScheduleEndDatepicker: !this.state.isOpenScheduleEndDatepicker
        })
    }

    handleChangeDisclaimer = event => {
        const { season } = this.state;
        season.Disclaimer = event.target.value;
        this.setState({ season });
    }

    toggleCalendarStart = e => {
        e && e.preventDefault()
        this.setState({
            isOpenStartDatepicker: !this.state.isOpenStartDatepicker
        })
    }
    toggleCalendarEnd = e => {
        e && e.preventDefault()
        this.setState({
            isOpenEndDatepicker: !this.state.isOpenEndDatepicker
        })
    }

    changeCoachAllowance = i => {
        const { season } = this.state;

        switch (i) {
            case 1: // change scores
                season.CoachCanChangeScore = !season.CoachCanChangeScore;
                break;
            case 2: // add games
                season.CoachCanAddGames = !season.CoachCanAddGames;
                break;
            case 3: // add players
                season.CoachCanAddPlayers = !season.CoachCanAddPlayers;
                break;
            default:
                break;
        }

        this.setState({
            season
        });
    }
    enableCoachRole = i => {
        const { season } = this.state;

        switch (i) {
            case 1: // Coach
                season.Roles.Coach = !season.Roles.Coach;
                break;
            case 2: // Assistant
                season.Roles.Assistant = !season.Roles.Assistant;
                break;
            case 3: // Team Manager
                season.Roles.TeamManager = !season.Roles.TeamManager;
                break;
            case 4: // Allow Open Registration
                season.Roles.AllowOpenRegistration = !season.Roles.AllowOpenRegistration;
                break;
            default:
                break;
        }

        this.setState({
            season
        });
    }

    togglePayment = (i) => {
        const { season } = this.state;
        season.Payment[i] = !season.Payment[i];
        this.setState({ season });
    }
    toggleDisplay = (i) => {
        const { season } = this.state;
        season.ShowEntryListOrGroup = i;
        this.setState({ season });
    }

    next = (step) => this.setState({ step });

    isValid = () => {
        const { season, validations } = this.state;
        validations.type = season.IdSeasonType ? true : false;
        validations.name = season.Name ? true : false;
        this.setState({ validations, incompleteForm: _.values(validations).includes(false) ? true : false });
        return !_.values(validations).includes(false);
    }



    selectTournamentPointsType = e => {
        const { season } = this.state;
        season.IdTournamentType = e.target.value;
        this.setState({ season });
    }
    selectSeasonType = e => {
        const { season } = this.state;
        season.IdSeasonType = parseInt(e.target.value, 10);

        // NEW RULE: if its a tournament, auto-check some things
        if (!season.Id && !season.IdSeason) {
            if ([3, 5].indexOf(parseInt(e.target.value, 10)) !== -1) {
                season.Roles = {
                    Coach: true,
                    Assistant: true,
                    TeamManager: true,
                    AllowOpenRegistration: false
                };
                season.CoachCanAddPlayers = true;
            } else {
                season.Roles = {
                    Coach: false,
                    Assistant: false,
                    TeamManager: false,
                    AllowOpenRegistration: false
                };
                season.CoachCanAddPlayers = false;
            }
        }
        this.setState({ season }, () => { this.isValid(); });
    }
    selectTournamentSeason = e => {
        const { season } = this.state;
        season.IdTournamentSeason = e.target.value;
        this.setState({ season });
    }
    handleChangeName = e => {
        const { season } = this.state;
        season.Name = e.target.value;
        this.setState({ season }, () => { this.isValid(); });
    }

    onSelectMedal = (index) => {
        const { medals, season } = this.state;
        medals.forEach((medal, i) => { medal.selected = index === i });
        season.IdMedal = medals[index].IdMedal;
        this.setState({ medals, picking_medal: false, season });
    }

    togglePoolplayGuests = () => {
        const { season } = this.state;
        season.AllowGuestPlayersAtPoolplay = !season.AllowGuestPlayersAtPoolplay;
        this.setState({ season });
    }

    togglePlayoffGuests = () => {
        const { season } = this.state;
        season.AllowGuestPlayersAtPlayoff = !season.AllowGuestPlayersAtPlayoff;
        this.setState({ season });
    }

    selectMembershipProgram = (e) => {
        const { season } = this.state;
        season.IdMembership = e.target.value;
        this.setState({ season });
    }

    render() {

        const { isOpen, toggle, tournamentPointsTypes, tournamentSeasons, membership_programs } = this.props,
            { season, microTransac, step, error, validations, dirty, incompleteForm, medals } = this.state;

        const seasonTypes = [
            {
                Id: 0,
                Name: 'Select...'
            },
            ...config.seasonTypes];

        return (
            <Modal isOpen={isOpen} style={{ maxWidth: 600 }}>
                <ModalHeader toggle={toggle} className={`header-${season.IdSeasonType}`} style={{}}>
                    {step === 1 && <span>{season.IdSeason ? season.Name : 'Season basics'}</span>}
                    {step === 2 && <span>Step 2: Divisions of {season.Name}</span>}
                </ModalHeader>
                <ModalBody id="create-season-popup">
                    <div className="d-flex flex-column p-4">
                        <div className="w-100">
                            {/* Name of season */}
                            <FormGroup className="margin-top-half">
                                <Label>Name <i className={`fa fa-asterisk ${(!validations.name && dirty) ? 'red' : 'gray'}`} /></Label>
                                <input className={`${(!validations.name && dirty) ? 'input-danger' : ''} form-control`} type="text" defaultValue={season.Name} onChange={this.handleChangeName}
                                    placeholder="Name of the season" ref={(input) => this.txtSeasonName = input} />
                            </FormGroup>

                            <div className="d-flex flex-row w-100">

                                {/* Dates */}
                                <div className="w-25 ">
                                    <span className="black font-8">Season Dates</span>
                                    <div className="d-flex flex-row justify-content-center w-100 bg-gray-dark white" style={{ borderRadius: 5 }}>
                                        <div className="align-self-center">
                                            <DatePicker className="white" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
                                                customInput={<CustomDatePicker />} selected={moment(season.DateStart).utc()} selectsStart
                                                startDate={moment(season.DateStart).utc()} endDate={moment(season.DateEnd).utc()} onChange={this.handleChangeStart} />
                                        </div>
                                        <span className="align-self-center m-1">to</span>
                                        <div className="align-self-center">
                                            <DatePicker className="white" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={moment(season.DateEnd).utc()} selectsStart
                                                startDate={moment(season.DateStart).utc()} endDate={moment(season.DateEnd).utc()} onChange={this.handleChangeEnd} />
                                        </div>
                                    </div>
                                </div>









                                {/* Registration End date */}
                                <div className="w-50 px-4">
                                    <span className="black font-8">Reg. Available Until</span>
                                    <div className="d-flex flex-row w-100 bg-gray-dark white justify-content-center" style={{ borderRadius: 5 }}>
                                        <div className="align-self-center">
                                            <DatePicker showTimeSelect timeIntervals={1} shouldCloseOnSelect={false} className="white" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker4 />} selected={moment(season.RegistrationEnd || season.DateEnd).utc()} onChange={this.handleChangeRegistrationDate} />
                                        </div>

                                        <div className="align-self-center mx-2 d-flex flex-column justify-content-center">
                                            <button onClick={() => {
                                                const { season } = this.state;
                                                season.RegistrationEnd = moment(season.RegistrationEnd || season.DateEnd).add(1, 'minute');
                                                this.setState({ season });
                                            }} className="btn btn-sm btn-link align-self-center"><i className="fas fa-arrow-up font-8" /></button>
                                            <span className="white font-12 line1">{moment(season.RegistrationEnd || season.DateEnd).utc().format('hh:mmA')}</span>
                                            <button onClick={() => {
                                                const { season } = this.state;
                                                season.RegistrationEnd = moment(season.RegistrationEnd || season.DateEnd).add(-1, 'minute');
                                                this.setState({ season });
                                            }} className="btn btn-sm py-0 btn-link align-self-center"><i className="fas fa-arrow-down font-8" /></button>
                                        </div>

                                        <div className="align-self-center">
                                            <Input type="select" value={this.state.timezone} onChange={
                                                e => { this.setState({ timezone: e.target.value }); }}>
                                                {this.state.timezones && this.state.timezones.map((type, i) => <option value={type.value} key={i}>
                                                    {type.title}
                                                </option>)}
                                            </Input>
                                        </div>

                                    </div>

                                </div>








                                {/* Schedule dates */}
                                <div className="w-25">
                                    <span className="black font-8">Schedule From</span>
                                    {/* Schedule Dates */}
                                    <div className="d-flex flex-row justify-content-center w-100 bg-gray-dark white" style={{ borderRadius: 5 }}>
                                        <div className="align-self-center">
                                            <DatePicker className="white" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
                                                customInput={<CustomDatePicker />} selected={season.ScheduleStartDate ? moment(season.ScheduleStartDate).utc() : null} selectsStart
                                                startDate={season.ScheduleStartDate ? moment(season.ScheduleStartDate).utc() : null} endDate={season.ScheduleEndDate ? moment(season.ScheduleEndDate).utc() : null} onChange={this.handleChangeScheduleStart} />
                                        </div>
                                        <span className="align-self-center m-1">to</span>
                                        <div className="align-self-center">
                                            <DatePicker className="white" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />}
                                                selected={season.ScheduleEndDate ? moment(season.ScheduleEndDate).utc() : null} selectsStart
                                                startDate={season.ScheduleStartDate ? moment(season.ScheduleStartDate).utc() : null}
                                                endDate={season.ScheduleEndDate ? moment(season.ScheduleEndDate).utc() : null} onChange={this.handleChangeScheduleEnd} />
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <FormGroup className="margin-top">
                                <Label>Disclaimer</Label>
                                <Input rows="8" placeholder="You might wanna copy and paste here..." type="textarea" name="disclaimer" value={season.Disclaimer} onChange={this.handleChangeDisclaimer}
                                />
                            </FormGroup>

                            <FormGroup className="no-padding margin-top-half">
                                <Label>Membership Program</Label>
                                <Input type="select" valid={season.IdMembership ? true : false} name="membershipProgram" value={season.IdMembership} onChange={this.selectMembershipProgram}>
                                    <option value={0} >
                                        None
                                    </option>
                                    {membership_programs && membership_programs.map((type, i) => <option value={type.IdMembership} key={i}>
                                        {`${type.Description} - (USD ${formatPriceWithoutCurrency(type.Amount * 100)})`}
                                    </option>)}
                                </Input>
                            </FormGroup>

                            <FormGroup className="no-padding margin-top-half">
                                <Label>Season Type <i className={`fa fa-asterisk ${(!validations.type && dirty) ? 'red' : 'gray'}`} /></Label>
                                <Input className={`${(!validations.type && dirty) ? 'input-danger' : ''}`} type="select" valid={season.IdSeasonType > 0} name="seasonType" value={season.IdSeasonType} onChange={this.selectSeasonType}>
                                    {seasonTypes.map((type, i) => <option value={type.Id} key={i}>
                                        {type.Name}
                                    </option>)}
                                </Input>
                            </FormGroup>
                            {season.IdSeasonType === 3 && <FormGroup className="no-padding margin-top-half">
                                <Label>Tournament season type  </Label>
                                <Input type="select" name="seasonType" value={season.IdTournamentSeason || 0} onChange={this.selectTournamentSeason}>
                                    <option value={0} >
                                        None
                                    </option>
                                    {tournamentSeasons && tournamentSeasons.map((type, i) => <option value={type.IdTournamentSeason} key={i}>
                                        {type.TournamentSeasonName}
                                    </option>)}
                                </Input>
                            </FormGroup>}

                            {season.IdSeasonType === 3 && <FormGroup className="no-padding margin-top-half">
                                <Label>'Points' style</Label>
                                <Input type="select" name="seasonType" value={season.IdTournamentType || 0} onChange={this.selectTournamentPointsType}>
                                    <option value={0} >
                                        None
                                    </option>
                                    {tournamentPointsTypes && tournamentPointsTypes.map((type, i) => <option value={type.IdTournamentType} key={i}>
                                        {type.TournamentType}
                                    </option>)}
                                </Input>
                            </FormGroup>}
                            <hr />

                            <div className="d-flex flex-row">

                                <FormGroup className="w-100 d-flex flex-column ">
                                    <Label>Allow Coaches to:</Label>
                                    <button onClick={() => this.changeCoachAllowance(1)} className={`btn btn-sm btn-default  d-flex flex-row `}>
                                        <i className={`fa-${season.CoachCanChangeScore ? 'check-square fa green' : 'square far'} align-self-center`} />
                                        <span className="margin-left align-self-center">Change Scores</span></button>
                                    <button onClick={() => this.changeCoachAllowance(2)} className={`btn btn-sm btn-default  d-flex flex-row `}>
                                        <i className={`fa-${season.CoachCanAddGames ? 'check-square fa green' : 'square far'} align-self-center`} />
                                        <span className="margin-left align-self-center">Add Games</span></button>
                                    <button onClick={() => this.changeCoachAllowance(3)} className={`btn btn-sm btn-default  d-flex flex-row `}>
                                        <i className={`fa-${season.CoachCanAddPlayers ? 'check-square fa green' : 'square far'} align-self-center`} />
                                        <span className="margin-left align-self-center">Add Players</span></button>
                                </FormGroup>


                                <FormGroup className="w-100 d-flex flex-column divider-vertical pl-4">
                                    <Label>Coach / Team Admin roles:</Label>
                                    <button onClick={() => this.enableCoachRole(1)} className={`btn btn-sm btn-default  d-flex flex-row `}>
                                        <i className={`fa-${season.Roles.Coach ? 'check-square fa green' : 'square far'} align-self-center`} />
                                        <span className="margin-left align-self-center">Coach</span></button>
                                    <button onClick={() => this.enableCoachRole(2)} className={`btn btn-sm btn-default  d-flex flex-row `}>
                                        <i className={`fa-${season.Roles.Assistant ? 'check-square fa green' : 'square far'} align-self-center`} />
                                        <span className="margin-left align-self-center">Assistant Coach</span></button>
                                    <button onClick={() => this.enableCoachRole(3)} className={`btn btn-sm btn-default  d-flex flex-row `}>
                                        <i className={`fa-${season.Roles.TeamManager ? 'check-square fa green' : 'square far'} align-self-center`} />
                                        <span className="margin-left align-self-center">Team Manager</span></button>
                                </FormGroup>

                                {/* MEDAL */}
                                <section>
                                    {medals && season && parseInt(season.IdLeague, 10) === 11826 && (

                                        <div id="medals_button" style={{ width: 100, height: 150 }} className="d-flex flex-column justify-content-center" onClick={() => this.setState({ picking_medal: !this.state.picking_medal })}>
                                            {!_.find(medals, m => m.selected) && <i className="align-self-center font-40 blue icon-competitive_icon" />}
                                            {!_.find(medals, m => m.selected) && <span className="black font-10 align-self-center">Pick Medal</span>}
                                            {_.find(medals, m => m.selected) && <img alt="" style={{ height: 100 }} src={`/images/medals/${_.find(medals, m => m.selected).MedalImage}_on.png`} className="align-self-center" />}
                                            {_.find(medals, m => m.selected) && <span className="black font-109 align-self-center">{_.find(medals, m => m.selected).MedalName}</span>}

                                        </div>
                                    )}

                                    {medals && season && parseInt(season.IdLeague, 10) === 11826 && (
                                        <Popover placement="right" isOpen={this.state.picking_medal} target="medals_button" toggle={() => this.setState({ picking_medal: false })}>
                                            <PopoverBody>
                                                { /* Step 1 */}
                                                {medals && medals.map((medal, i) => <button onClick={() => this.onSelectMedal(i)} className={`w-100 d-flex flex-row btn btn-${medal.selected ? 'info' : 'link'}`} key={i}>
                                                    <span className="align-self-center">{medal.MedalName}</span>
                                                    <img alt="" style={{ height: 25 }} className="ml-auto" src={`/images/medals/${medal.MedalImage}_on.png`} />
                                                </button>)}
                                            </PopoverBody>
                                        </Popover>
                                    )}
                                </section>

                            </div>

                        </div>

                        <hr />

                        <div className="w-100">

                            <section>

                                <div className="d-flex flex-row">
                                    <FormGroup className="no-padding d-flex flex-column w-100">
                                        <Label>Forms of Payment:</Label>
                                        <button onClick={() => this.togglePayment("Cash")} className={`btn btn-sm btn-default  d-flex flex-row`}><i className="fa fa-money-bill align-self-center margin-right" />
                                            {' '}Cash <i className={`fa-${season.Payment.Cash ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.togglePayment("Check")} className={`btn btn-sm btn-default  d-flex flex-row`}><i className="fa fa-list-alt align-self-center margin-right" />
                                            {' '}Check <i className={`fa-${season.Payment.Check ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.togglePayment("Visa")} className={`btn btn-sm btn-default  d-flex flex-row`}><i className="fab fa-cc-visa align-self-center margin-right" />
                                            {' '}Visa <i className={`fa-${season.Payment.Visa ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.togglePayment("MasterCard")} className={`btn btn-sm btn-default  d-flex flex-row`}><i className="fab fa-cc-mastercard align-self-center margin-right" />
                                            {' '}MasterCard <i className={`fa-${season.Payment.MasterCard ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.togglePayment("Amex")} className={`btn btn-sm btn-default  d-flex flex-row`}><i className="fab fa-cc-amex align-self-center margin-right" />
                                            {' '}Amex <i className={`fa-${season.Payment.Amex ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.togglePayment("Discover")} className={`btn btn-sm btn-default d-flex flex-row`}><i className="fab fa-cc-discover align-self-center margin-right" />
                                            {' '}Discover <i className={`fa-${season.Payment.Discover ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <hr />
                                        <Label className="mt-2">Individual Registration:</Label>
                                        <button onClick={() => {
                                            const { season } = this.state;
                                            season.AllowsScoutRegistration = !season.AllowsScoutRegistration;
                                            this.setState({ season });
                                        }} className={`btn btn-sm btn-default d-flex flex-row`}>Scout<i className={`fa-${season.AllowsScoutRegistration ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => {
                                            const { season } = this.state;
                                            season.PreventsCoachRegistration = !season.PreventsCoachRegistration;
                                            this.setState({ season });
                                        }} className={`btn btn-sm btn-default d-flex flex-row`}>Coaches<i className={`fa-${!season.PreventsCoachRegistration ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                    </FormGroup>
                                    <div style={{ width: 50 }} className="" />
                                    <FormGroup className="no-padding d-flex flex-column w-100 divider-vertical  pl-4" >
                                        <Label>Display:</Label>
                                        {/* TODO: Default to Hide Entries if the season doesn't tell */}
                                        <button onClick={() => this.toggleDisplay(1)} className={`btn btn-sm btn-default  d-flex flex-row`}>
                                            Hide Entries <i className={`fa-${season.ShowEntryListOrGroup === 1 ? 'check-circle fa green' : 'circle far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.toggleDisplay(2)} className={`btn btn-sm btn-default  d-flex flex-row`}>
                                            Show Entries <i className={`fa-${season.ShowEntryListOrGroup === 2 ? 'check-circle fa green' : 'circle far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.toggleDisplay(3)} className={`btn btn-sm btn-default  d-flex flex-row`}>
                                            Show Groupings <i className={`fa-${season.ShowEntryListOrGroup === 3 ? 'check-circle fa green' : 'circle far'} ml-auto align-self-center`} /></button>
                                        <button className={`btn btn-sm btn-default  d-flex flex-row`}>
                                            Coaches Shirts <i className={`fa-${season.CoachesShirts === 3 ? 'check-circle fa green' : 'circle far'} ml-auto align-self-center`} /></button>

                                        <hr />

                                        <Label>Guest Players:</Label>
                                        <button onClick={() => this.togglePoolplayGuests()} className={`btn btn-sm btn-default  d-flex flex-row`}>
                                            Poolplay <i className={`fa-${season.AllowGuestPlayersAtPoolplay ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                        <button onClick={() => this.togglePlayoffGuests()} className={`btn btn-sm btn-default  d-flex flex-row`}>
                                            Playoffs <i className={`fa-${season.AllowGuestPlayersAtPlayoff ? 'check-square fa green' : 'square far'} ml-auto align-self-center`} /></button>
                                    </FormGroup>
                                </div>
                            </section>
                        </div>
                    </div>


                </ModalBody>

                {incompleteForm && dirty && <ModalBody className="bg-danger text-center white">Please complete the required fields <i className="fa fa-asterisk" /></ModalBody>}

                {this.state.updated && <ModalBody className="bg-success text-center white p-4" onClick={toggle}>Saved!</ModalBody>}
                {error && <ModalBody className="bg-danger text-center white">{error.message}</ModalBody>}
                {!microTransac && (
                    <ModalFooter>
                        {step === 2 && <button className="btn btn-sm btn-link" onClick={() => this.next(1)}>Back</button>}
                        {step === 1 && <button className="btn btn-sm btn-link" onClick={toggle}>Close</button>}
                        {(step === 1 && !season.IdSeason) && <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 130, height: 40 }} onClick={this.saveSeason}>Save Season</button>}
                        {(step === 2 || season.IdSeason) && <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 130, height: 40 }} onClick={this.saveSeason}>Save season</button>}
                    </ModalFooter>)}
                {microTransac && (
                    <ModalFooter>
                        <button className="btn btn-sm btn-success"><i className="fa fa-cog fa-spin fa-2x font-10" />
                            {' '}Saving season, please wait...</button>
                    </ModalFooter>
                )}
            </Modal>
        )
    }
}

export default SeasonPopup;