import React from 'react';
import TeamItem from '../common/cart_items/Team';
import CoachItem from '../common/cart_items/Coach';
import UserItem from '../common/cart_items/User';
import _ from 'lodash';
import BlackishHeader from '../../common/things/BlackishHeader';
import { formatPrice, detectCreditCardType } from '../../../helpers';
import PayingPlacebo from '../common/PayingPlacebo';
import request from 'superagent';
import config from '../../../config';
import fakeAuth from '../../../fakeAuth';
import { Animated } from 'react-animated-css';

import '../../externals/signature-pad.css';
import ScoutItem from '../common/cart_items/Scout';

var SignaturePad = require('react-signature-pad');

class Checkout extends React.Component {

    state = {
        bootstrapped: true,
        payByCheck: false,
        payByCash: false,
        signatureError: false,
        payByCheckAllowed: false,
        payByCashAllowed: false,
        paying: false,
        signatureDataURL: null,
        card: null,
        invalidCC: null,
        signed: false,
        adjustmentByDate: null,
        discounts: [],
        the_discount: 0
    }

    // Lifecycle
    componentWillMount() {
        const { params, setTitle, league, cart } = this.props;

        setTitle && setTitle({
            main: 'Checkout',
            sub: league ? (league.Name || league.LeagueName) : null
        });

        if (!league || !league.IdLeague) {
            this.props.leagueActions &&
                this.props.leagueActions.fetchLeague(params.idLeague)
        }

        // Fetch disclaimer of the 1st season ?
        if (league) {
            let filteredCart = league ? _.filter(cart, (item) => {
                return item.selectedLeague && item.selectedLeague.IdLeague === league.IdLeague;
            }) : null;
            if (filteredCart.length) {
                this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(filteredCart[0].selectedSeason.IdSeason);
            }

            this.setup(filteredCart);
        }
        //
    }

    componentDidMount() {
        document.getElementById('registration-top') && document.getElementById('registration-top').scrollIntoView();
    }

    componentWillReceiveProps = (nextProps) => {
        const { cart } = nextProps;

        if (!cart.length) {
            this.props.router && this.props.router.push('/registration');
        }

        if (nextProps.league !== this.props.league) {
            const { setTitle } = nextProps;
            setTitle && setTitle({
                main: 'Checkout',
                sub: (nextProps.league.Name || nextProps.league.LeagueName)
            });

            // Fetch disclaimer of the 1st season ?
            var filteredCart = nextProps.league ? _.filter(cart, (item) => {
                return item.selectedLeague && item.selectedLeague.IdLeague === nextProps.league.IdLeague;
            }) : null;
            if (filteredCart.length) {
                this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(filteredCart[0].selectedSeason.IdSeason);
                this.setup(filteredCart);
            } else {
                // POPUP THERE ARE NO ITEMS and REDIRECT TO /registration
                this.props.router && this.props.router.push('/registration');
            }
            //
        }
    }
    //

    setup = filteredCart => {
        let payByCheckAllowed = true,
            payByCashAllowed = true;

        let memberships_applied = [];

        filteredCart.forEach(item => {

            if (!item.selectedSeason.Check) {
                payByCheckAllowed = false;
            }
            if (!item.selectedSeason.Cash) {
                payByCashAllowed = false;
            }

            this.setState({ payByCashAllowed, payByCheckAllowed });

            let force = _.after(4, () => {
                this.forceUpdate();
            });

            // Fetch Adjustments
            item.adjustments = [];

            // Hybrid seasons do not have adjustments when registering the team
            if (item.selectedSeason.IdSeasonType === 5 && item.selectedTeam && !item.selectedSeason.IdPendingRegistration) return;

            // ByDate
            let url = [config.apiEndpoint,
                'api/v4/registration/adjustments/byDate',
            (item.selectedDivision || { IdDivision: 0 }).IdDivision, // IdDivision
            (item.selectedTeam && !item.selectedSeason.IdPendingRegistration) ? item.selectedTeam.IdTeam : item.selectedUser.IdUser, // IdEntity
            (item.selectedTeam && !item.selectedSeason.IdPendingRegistration) ? 1 : (item.mode === 1 ? 3 : 4), // IdRole 
            (item.selectedTeam && !item.selectedSeason.IdPendingRegistration) ? 2 : 1].join('/'); // Mode

            request.get(url)
                .then(data => {
                    if (data.body.success && data.body.adjustments) {
                        item.adjustments = [...item.adjustments, ...data.body.adjustments];
                    }
                    force(); // 1
                });

            // ByZip (Only for players) RIGHT HERE
            //if (!item.selectedTeam && item.mode === 1) {
            url = [config.apiEndpoint,
                'api/v4/registration/adjustments/byZip2',
            (item.selectedDivision || { IdDivision: 0 }).IdDivision, // IdDivision
            fakeAuth.getUser().IdUser, item.selectedTeam ? 3 : (item.selectedRole ? item.selectedRole.Id : 3)].join('/');

            request.get(url)
                .then((data) => {
                    if (data.body.success) {
                        item.adjustments = [...item.adjustments, ...data.body.adjustments];
                    }
                    force(); // 2
                });
            //}

            // TODO: get season fees!
            request.get(`${config.apiEndpoint}/api/v4/registration/fee/${item.selectedSeason.IdSeason}`)
                .then(data => {
                    // Only add it as an adjustment if the fee is not bundled                    
                    item.SLFEE = item.selectedTeam ? data.body.fee : ((item.selectedRole || { Id: 3 }).Id === 3 ? data.body.fee : null);
                    if (data.body.fee && data.body.fee.IdFeeType === 2 && (data.body.fee.PlayerRegistrationFee || data.body.fee.TeamRegistrationFee) && item.SLFEE) {
                        item.adjustments = [...item.adjustments, {
                            ProgramName: 'Service Fee',
                            Adjustment: data.body.fee.PlayerRegistrationFee || data.body.fee.TeamRegistrationFee,
                            SPORTSLOGIC: true
                        }];
                    }

                    force(); // 3
                });

            // NEW Get Memberships
            if (item.selectedUser.IdUser) {
                request.get(`${config.apiEndpoint}/api/v6/registration/membership/${item.selectedSeason.IdSeason}/${item.selectedUser.IdUser}`)
                    .then(({ body }) => {
                        if (body.membership_program) {
                            if (_.find(memberships_applied, ma => ma.IdMembership === body.membership_program.IdMembership &&
                                ma.IdUser === item.selectedUser.IdUser)) {
                                body.membership_program = null;
                            }
                            if (body.membership_program) {
                                item.membership_program = body.membership_program;
                                item.adjustments = [...item.adjustments, {
                                    ProgramName: body.membership_program.Description,
                                    Adjustment: body.membership_program.Amount,
                                    MEMBERSHIP: true
                                }];
                            }
                            memberships_applied.push({
                                IdUser: item.selectedUser.IdUser,
                                IdMembership: body.membership_program.IdMembership
                            });
                        }
                        force(); // 4
                    });
            } else force(); // 4
        });

    }

    togglePayByCheck = () => {
        this.setState({ payByCash: false, payByCheck: !this.state.payByCheck });
    }

    togglePayByCash = () => {
        this.setState({ payByCash: !this.state.payByCash, payByCheck: false });
    }

    deleteItem = (id) => {
        this.props.cartActions && this.props.cartActions.removeItem(id);
    }

    calculateTotal = () => {
        const { cart, league } = this.props;

        const filteredCart = league ? _.filter(cart, (item) => {
            return item.selectedLeague && item.selectedLeague.IdLeague === league.IdLeague;
        }) : null;

        let total = 0;

        filteredCart && filteredCart.forEach(item => {

            if (!item.selectedDivision || (item.selectedDivision && !item.selectedDivision.IsDivisionTryout && !item.selectedDivision.IsDivisionWaitlisted)) {
                // ^ Scouts
                
                let reduced_questions = _.chain([...item.questions] || []).map(q => {
                    return q.answer ? q.answer.Amount : 0;
                }).reduce((a, b) => a + b, 0).value();

                let reduced_adjustments = _.chain(item.adjustments || [])
                    .map(q => q.Adjustment || 0)
                    .reduce((a, b) => a + b, 0).value()

                total += parseFloat(((item.selectedPlan && item.selectedPlan.Dues.length) ? item.selectedPlan.Dues[0].Total : 0)) + reduced_questions + reduced_adjustments;
            }
        });

        if (total < 0) total = 0;
        return total;
    }

    onCoupon = () => {
        const { cart } = this.props, item = _.first(cart), { discounts } = this.state;
        let { the_discount } = this.state;
        request.post(`${config.apiEndpoint}/api/v4/coupons/apply`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .send({
                IdLeague: (item.selectedLeague || item.selectedSeason).IdLeague,
                IdSeason: item.selectedSeason.IdSeason,
                CouponName: this.txtCoupon.value
            })
            .then(({ body }) => {
                // Only add it as an adjustment if the fee is not bundled                    
                const { coupon } = body;

                if (_.find(discounts, c => c.coupon.IdCoupon === coupon.IdCoupon)) {
                    // COUPON ALREADY APPLIED
                    return;
                }

                let total = this.calculateTotal();

                discounts.push({
                    coupon,
                    Code: this.txtCoupon.value,
                    Value: coupon.AmountPerCoupon || (total * coupon.PercentPerCoupon / 100)
                });
                the_discount += (coupon.AmountPerCoupon || (total * coupon.PercentPerCoupon / 100));
                this.setState({ discounts, the_discount, coupon_error: false });
            }, (error) => {
                this.setState({ coupon_error: true })
            });
    }

    validateNewCard = () => {
        var valid = true;
        if (!this.txtCardNumber.value ||
            !this.txtNameOnCard.value ||
            !this.txtExpDate.value ||
            !this.txtCVV.value ||
            !this.txtZipCode.value) {
            valid = false;
        } else if (!detectCreditCardType(this.txtCardNumber.value).Name) {
            valid = false;
        }
        return valid;
    }

    continue = () => {
        var signature = this.refs.mySignature;
        if (signature && signature.isEmpty()) {
            this.setState({
                signatureError: true
            });
            document.getElementById('terms-and-conditions').scrollIntoView();

        } else {
            this.setState({
                signatureDataURL: signature.toDataURL()
            }, function () {
                document.getElementById('btnPay').scrollIntoView();
            });
        }
    }

    clearSignature = () => {
        var signature = this.refs.mySignature;
        signature.clear();
        this.setState({
            signatureDataURL: null,
            signed: false
        })
    }

    signatureEnd = () => {
        this.setState({
            signed: true
        });
    }

    pay = () => {
        const { the_discount = 0 } = this.state;

        // TODO: validate everything!
        var total = this.calculateTotal();
        var valid = false;

        const { payByCheck, payByCash } = this.state;

        var selectedCard = {};

        if (payByCheck) {
            selectedCard = {
                check: true
            }
            valid = true;
        } else if (payByCash) {
            selectedCard = {
                cash: true
            }
            valid = true;
        } else if ((total - the_discount) <= 0) {
            selectedCard = { cash: true }
            valid = true;
        } else if (this.validateNewCard()) {
            selectedCard = {
                number: this.txtCardNumber.value,
                name: this.txtNameOnCard.value,
                cvv: this.txtCVV.value,
                expdate: this.txtExpDate.value,
                expDate: this.txtExpDate.value,
                zip: this.txtZipCode.value
            }
            valid = true;
        } else {
            // Invalid Credit Card
            document.getElementById('lblTotalWrapper').scrollIntoView();
        }

        valid && this.setState({
            signatureError: false,
            paying: true,
            card: selectedCard,
            invalidCC: false
        });

        !valid && this.setState({
            invalidCC: true
        });

    }

    togglePayingPlacebo = () => {
        this.setState({
            paying: false
        })
    }

    successPayment = () => {
        // Redirect to /registration ?
        this.props.router && this.props.router.push('/registration')
    }

    render() {
        const { cart, league } = this.props,
            { bootstrapped, signatureError, payByCashAllowed, payByCheckAllowed, paying,
                invalidCC, signatureDataURL, signed, the_discount } = this.state;

        let filteredCart = league ? _.filter(cart, (item) => {
            return item.selectedLeague && item.selectedLeague.IdLeague === league.IdLeague;
        }) : null;

        let season = (_.first(filteredCart) || {}).selectedSeason;

        let total = this.calculateTotal();

        let cartToSend = {
            items: filteredCart
        }

        return (
            <section className="w3-container">
                <div className="w3-content" key="row" id="registration-top">
                    <div style={{ height: 30 }} />
                    <span className="font-18 toBold black mt-4 toUpper sqwad-font">Cart Summary</span>
                    {bootstrapped && league && (
                        <div className="d-flex flex-column">
                            {filteredCart && filteredCart.map((item, i) => (
                                <div key={`${item.selectedUser.IdUser}-${(item.selectedDivision || { IdDivision: 0 }).IdDivision}-${i}`} className="card tile shadow-box rounded-item align-self-center w-100" >
                                    {item.selectedTeam && <TeamItem item={item} fnDelete={this.deleteItem} />}
                                    {!item.selectedTeam && item.mode === 1 && <UserItem item={item} fnDelete={this.deleteItem} />}
                                    {!item.selectedTeam && item.mode === 2 && <CoachItem item={item} fnDelete={this.deleteItem} />}
                                    {!item.selectedTeam && item.mode === 5 && <ScoutItem item={item} fnDelete={this.deleteItem} />}
                                </div>)
                            )}
                            {(!filteredCart || !filteredCart.length > 0) && <h1 key="nono" className="text-center white">Sorry, there are no items in your cart for {league.LeagueName}</h1>}
                        </div>)}

                    {/* TERMS */}
                    {this.props.disclaimer && (
                        <div className=" no-margin no-padding d-flex flex-column">
                            <span className="font-18 toBold black mt-4 toUpper sqwad-font">Terms and Conditions</span>
                            <div id="terms-and-conditions" style={{ height: 300, overflowY: 'scroll' }} className="black m-auto font-10 card tile shadow-box">

                                {this.props.disclaimer.fee && (
                                    <section>
                                        {season && <BlackishHeader message='Service Fee' />}
                                        <div className="p-2" dangerouslySetInnerHTML={{ __html: this.props.disclaimer.fee }} />
                                    </section>
                                )}

                                {this.props.disclaimer.season && (
                                    <section>
                                        {season && <BlackishHeader message={season.Name || season.SeasonName} />}
                                        <div className="p-2" dangerouslySetInnerHTML={{ __html: this.props.disclaimer.season }} />
                                    </section>
                                )}

                                {this.props.disclaimer.sportslogic && (
                                    <section>
                                        <BlackishHeader message="SPORTS LOGIC" />
                                        <div className="p-2" dangerouslySetInnerHTML={{ __html: this.props.disclaimer.sportslogic }} />
                                    </section>
                                )}

                            </div>

                            { /* Signature */}
                            {!signatureError && <span className="font-18 toBold black mt-4 toUpper sqwad-font">Please sign here</span>}
                            {signatureError && <div className="bg-warning p-2 big-font text-center">Please Sign!</div>}
                            <div className="m-auto w-100 card tile shadow-box bg-white" style={{ position: 'relative' }}>
                                <SignaturePad onEnd={this.signatureEnd} ref="mySignature" className='w-100' />
                                <button className="btn btn-link float-right" onClick={this.clearSignature} style={{ position: 'absolute', top: 0, right: 0 }}>Clear</button>
                            </div>
                            {!signatureDataURL && this.props.disclaimer && <button className={`mb-4 btn btn-lg btn-block btn-${signed ? 'success' : 'default bg-gray white'} bigest-font`} onClick={this.continue}>
                                Continue
                            </button>}
                            { /* Credit Card */}
                            {signatureDataURL && (
                                <section className="mt-4 ">

                                    <div className="tile card shadow-box p-2 text-center mt-4 d-flex flex-column" id="lblTotalWrapper">
                                        <span className="jeff_blue bigest-font">Total: {formatPrice(total * 100)}</span>
                                        {the_discount > 0 && <span className="black font-10">Discount: -{formatPrice(the_discount * 100)}</span>}
                                    </div>

                                    {total > 0 && <div className="font-18 toBold black mt-4 sqwad-font toUpper">Coupon Code</div>}

                                    <section className={`p-2 m-auto font-12 align-self-center ${total <= 0 ? 'hide' : null} card tile shadow-box mb-4`}>
                                        <div className="p-2 d-flex flex-row">
                                            <input className="form-control align-self-center p-4 font-20" maxLength="16" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtCoupon = i} />
                                            <button onClick={this.onCoupon} className="btn btn-default px-4" style={{ backgroundColor: 'gray', color: 'white' }}>Apply</button>
                                            {the_discount > 0 && <span className='green font-12 ml-2 align-self-center'>Discount applied!</span>}
                                            {this.state.coupon_error && <span className='red font-12 ml-2 align-self-center'>Invalid Coupon Code</span>}
                                        </div>
                                    </section>
                                    {(total - the_discount) > 0 && !invalidCC && <span className="font-18 toBold black mt-4 block sqwad-font toUpper">Credit Card Information</span>}
                                    {(total - the_discount) > 0 && invalidCC && <div className="bg-warning p-2 big-font text-center">Invalid Credit Card!</div>}
                                    <section className={`p-3 m-auto font-12 align-self-center ${(total - the_discount) <= 0 ? 'hide' : null} card tile shadow-box mt-4`}>
                                        <div className="margin-bottom-half d-flex flex-row">
                                            <label className="align-self-center">Card Number
                                                {' '}
                                                {(!this.txtCardNumber || !this.txtCardNumber.value) && <i className="red fa fa-asterisk" />}
                                            </label>
                                            <input className="form-control ml-auto align-self-center" maxLength="16" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtCardNumber = i} />
                                        </div>
                                        <div className="margin-bottom-half d-flex flex-row">
                                            <label className="align-self-center">Name on Card
                                                {' '}
                                                {(!this.txtNameOnCard || !this.txtNameOnCard.value) && <i className="red fa fa-asterisk" />}
                                            </label>
                                            <input className="form-control ml-auto align-self-center" type="text" style={{ maxWidth: 250 }} ref={(i) => this.txtNameOnCard = i} />
                                        </div>
                                        <div className="margin-bottom-half d-flex flex-row">
                                            <label className="align-self-center">Exp Date
                                                {' '}
                                                {(!this.txtExpDate || !this.txtExpDate.value) && <i className="red fa fa-asterisk" />}
                                            </label>
                                            <input className="form-control ml-auto align-self-center" maxLength="4" placeholder="MMYY" style={{ width: 100 }} type="text" ref={(i) => this.txtExpDate = i} />
                                        </div>
                                        <div className="margin-bottom-half d-flex flex-row">
                                            <label className="align-self-center">CVV
                                                {' '}
                                                {(!this.txtCVV || !this.txtCVV.value) && <i className="red fa fa-asterisk" />}
                                            </label>
                                            <input className="form-control ml-auto align-self-center" type="text" maxLength="4" style={{ width: 100 }} ref={(i) => this.txtCVV = i} />
                                        </div>
                                        <div className="d-flex flex-row">
                                            <label className="align-self-center">Zip / Postal
                                                {' '}
                                                {(!this.txtZipCode || !this.txtZipCode.value) && <i className="red fa fa-asterisk" />}
                                            </label>
                                            <input className="form-control ml-auto align-self-center" type="text" style={{ width: 100 }} ref={(i) => this.txtZipCode = i} />
                                        </div>
                                    </section>

                                    { /* Other Methods */}
                                    {(payByCheckAllowed || payByCashAllowed) && (total - the_discount) > 0 && <div className="font-18 toBold black mt-4">Other Payment Methods</div>}

                                    {payByCheckAllowed && (total - the_discount) > 0 && <button className="pointer card tile shadow-box font-16 d-flex flex-row p-2 w-100" style={{ borderTop: '1px solid #e5e5e5' }} onClick={this.togglePayByCheck}>
                                        <div className="d-flex flex-row w-100">
                                            <i className="fa fa-money-check-alt align-self-center" />
                                            <span className="idented-half align-self-center">Pay by Check</span>
                                            <i className={`${this.state.payByCheck ? 'fa fa-check-circle green' : 'far fa-circle'} ml-auto margin-right-half align-self-center`} />
                                        </div>
                                    </button>}

                                    {payByCashAllowed && (total - the_discount) > 0 && <button className="pointer card tile shadow-box mt-2 font-16 d-flex flex-row p-2 w-100" style={{ borderTop: '1px solid #e5e5e5' }} onClick={this.togglePayByCash}>
                                        <div className="d-flex flex-row w-100">
                                            <i className="fa fa-money-bill align-self-center" />
                                            <span className="idented-half align-self-center">Pay by Cash</span>
                                            <i className={`${this.state.payByCash ? 'fa fa-check-circle green' : 'far fa-circle'} ml-auto margin-right-half align-self-center`} /></div>
                                    </button>}

                                    {filteredCart && filteredCart.length && <button id="btnPay" className="btn mt-4 btn-lg btn-block btn-success bigest-font" onClick={this.pay}>
                                        {(total - the_discount) > 0 ? `Pay ${formatPrice((total - the_discount) * 100)}` : 'Register'}
                                    </button>}

                                    <div style={{ height: 50 }} />
                                </section>)}
                        </div>)}
                </div>
                {paying && <PayingPlacebo discounts={this.state.discounts} card={this.state.card} total={total} cart={cartToSend} league={league} toggle={this.togglePayingPlacebo} isOpen={paying}
                    cartActions={this.props.cartActions} signature={this.state.signatureDataURL} fnOk={this.successPayment} />}
            </section>)
    }
}

export default Checkout;
